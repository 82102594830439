<template>
  <div>
    <h1
      id="createAppTitle"
      class="text-center mb-1"
    >
      Create App
    </h1>
    <p class="text-center mb-2">
      Provide application data with this form
    </p>
    <div class="p-1">
      <validation-observer ref="add_app_form">
        <b-row class="mb-1">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="app name"
              :rules="{ required: true, min: 3, max: 100}"
            >
              <label for="app_name_input"><h5>Application Name</h5></label>
              <b-form-input
                id="app_name_input"
                v-model="app_name"
                placeholder="application name"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row class="mb-1">
          <b-col cols="12">
            <validation-provider
              #default="{ errors }"
              name="app url"
              :rules="{ required: true, url: true }"
            >
              <label for="app_url_input"><h5>Application Url</h5></label>
              <b-form-input
                id="app_url_input"
                v-model="app_url"
                placeholder="example: http://mydomain.com"
              />
              <small
                v-if="errors.length > 0"
                class="text-danger"
              >{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
    <div
      class=" px-3 py-2"
      style="text-align: center;"
    >
      <b-button
        variant="primary"
        class="mr-1"
        @click="submitData"
      >Submit
      </b-button>
    </div>
  </div>
</template>
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

extend('url', {
  validate(value) {
    return /^((?:https?:\/\/|www\.)(?:[-a-z0-9]+\.)*[-a-z0-9]+.*)$/.test(value)
  },
  message: 'This value must be a valid URL',
})
export default {
  name: 'AddNewApp',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      app_name: '',
      app_url: '',
      settings: {
        // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: 0.6,
      },
    }
  },
  computed: {
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
  },
  methods: {
    submitData() {
      this.$refs.add_app_form.handleSubmit(() => {
        const obj = {
          name: this.app_name,
          url: this.app_url,
        }
        this.showLoading()
        this.$http
          .post('/api/apps', obj)
          .then(response => {
            const statusCode = response.response ? response.response.status : null
            if (statusCode === null || statusCode === 200) {
              this.reset()
              this.$emit('added_app', response.data)
              this.$bvModal.hide('modal-add-new-app')
            }
          })
          .finally(() => {
            this.hideLoading()
          })
      })
    },
    reset() {
      this.app_name = ''
      this.app_url = ''
    },
  },
}
</script>
