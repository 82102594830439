<template>
  <div class="custom-pagination">
    <div
      v-show="data.total > 0"
      style="display: inline-block; float: left; line-height: 2rem;"
    >
      From {{ data.from }} to {{ data.to }} of {{ data.total }}
    </div>
    <div
      v-show="data.total > 0"
      class="con-vs-pagination vs-pagination-primary"
      style="display: inline-block; float: right;"
    >
      <ul
        class="pagination b-pagination"
        style="margin-bottom: 0;"
      >
        <li
          :class="{'disabled': !hasFirstPage}"
          class="page-item"
          @click="goToFirstPage()"
        >
          <span class="page-link"> &#171; </span>
        </li>
        <li
          :class="{'disabled': !hasPreviousPage}"
          class="page-item"
          @click="goToPreviousPage()"
        >
          <span class="page-link"> &#8249; </span>
        </li>
        <li
          v-for="(onePage, index) in listPages"
          :key="index"
          class="page-item"
          :class="{'active': onePage === page}"
          @click="goToPage(onePage)"
        >
          <span class="page-link"> {{ onePage }} </span>
        </li>
        <li
          :class="{'disabled': !hasNextPage}"
          class="page-item"
          @click="goToNextPage()"
        >
          <span class="page-link"> &#8250; </span>
        </li>
        <li
          :class="{'disabled': !hasLastPage}"
          class="page-item"
          @click="goToLastPage()"
        >
          <span class="page-link"> &raquo; </span>
        </li>
      </ul>
    </div>
    <div style="clear:both" />
  </div>
</template>

<script>
export default {
  name: 'PaginationAndTableInfo',
  props: ['data'],
  data() {
    return {
      page: 1,
    }
  },
  computed: {
    hasFirstPage() {
      return this.data.total_page > 0
          && this.page > 1
    },
    hasPreviousPage() {
      return this.data.total_page > 0
          && this.page > 1
    },
    hasNextPage() {
      return this.data.total_page > 0
          && this.page < this.data.total_page
    },
    hasLastPage() {
      return this.data.total_page > 0
          && this.page < this.data.total_page
    },
    listPages() {
      if (
        this.data.total_page === 0
          || this.data.total === 0
      ) return []
      if (this.data.total_page <= 5) {
        return Array.from({ length: Math.min(5, this.data.total_page) }, (x, i) => i + 1)
      }
      if (this.page <= 3) {
        return Array.from({ length: Math.min(5, this.data.total_page) }, (x, i) => i + 1)
      }
      if (this.page >= this.data.total_page - 2) {
        return [this.data.total_page - 4, this.data.total_page - 3, this.data.total_page - 2, this.data.total_page - 1, this.data.total_page]
      }
      return [this.page - 2, this.page - 1, this.page, this.page + 1, this.page + 2]
    },
  },
  watch: {
    data(newVal) {
      this.page = newVal.page
    },
  },
  methods: {
    goToPage(pageNumber) {
      this.page = pageNumber
      this.$emit('change', this.page)
    },
    goToFirstPage() {
      if (this.hasFirstPage) {
        this.goToPage(1)
      }
    },
    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.goToPage(this.page - 1)
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.goToPage(this.page + 1)
      }
    },
    goToLastPage() {
      if (this.hasLastPage) {
        this.goToPage(this.data.total_page)
      }
    },
  },
}
</script>

<style>
.custom-pagination .page-item {
  cursor: pointer;
}
</style>
